import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs"
import "@reach/tabs/styles.css"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useEffect } from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import Layout from "../components/layout"
import Seo from "../components/seo"
import zoomIcon from "../images/zoom.svg"
import "../styles/urunler.css"
// import { useTranslation } from 'gatsby-plugin-react-i18next';

function getShuffledAllProducts(products) {
  return products
    .filter(product => {
      return product.frontmatter.kategori?.toLowerCase() !== "kristal taşlar"
    })
    .sort(() => Math.random() - 0.5)
}

const Products = props => {
  const { language } = useI18next()
  const [activeTab, setActiveTab] = React.useState(0)
  const [filteredProducts, setFilteredProducts] = React.useState([])

  const categories = [
    { title: "Tüm Ürünler", title_en: "All Products" },
    ...props.data?.categories?.nodes?.map(category => category.frontmatter),
  ]
  const products = props.data?.products?.nodes

  useEffect(() => {
    setFilteredProducts(getShuffledAllProducts(products))
    document.body.className = "products"
    return () => {
      document.body.className = ""
    }
  }, [products])

  const options = {
    buttons: {
      showFullscreenButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false,
      showAutoplayButton: false,
    },
  }
  return (
    <Layout>
      <Seo title="Ürünler" />
      <div className="mt-60 w-9/12 mx-auto md:w-11/12 md:mt-24">
        <Tabs
          index={activeTab}
          onChange={index => {
            setActiveTab(index)
            const cat = categories[index]?.title.toLowerCase()
            if (index === 0) {
              setFilteredProducts(getShuffledAllProducts(products))
            } else {
              setFilteredProducts(
                products.filter(
                  product => product.frontmatter.kategori?.toLowerCase() === cat
                )
              )
            }
          }}
        >
          <TabList>
            {categories
              ?.sort((a, b) => {
                if (a.order > b.order) {
                  return 1
                }
                if (a.order < b.order) {
                  return -1
                }
                return 0
              })
              .map((category, index) => (
                <Tab key={index}>
                  {(language === "en"
                    ? category.title_en
                    : category.title
                  ).toLocaleUpperCase()}
                </Tab>
              ))}
          </TabList>

          <TabPanels className="mt-20 md:mt-5">
            {categories?.map((category, index) => {
              return (
                <TabPanel key={category.title}>
                  <div className="w-full flex justify-between flex-wrap">
                    {filteredProducts?.map((product, index) => (
                      <div
                        key={product.frontmatter.title + index}
                        className="w-[48%] max-h-64 md:max-h-fit bg-white rounded-md shadow-md mb-12 flex items-stretch xl:w-full md:flex-col md:mb-4"
                      >
                        <div className="w-4/12 relative flex items-center md:w-1/2 md:mt-5 md:mx-auto md:justify-center">
                          <SimpleReactLightbox>
                            <SRLWrapper options={options}>
                              {product.frontmatter.urun_resimleri.map(
                                (image, index) => (
                                  <img
                                    key={image.resim}
                                    src={image.resim}
                                    alt=""
                                    className="cursor-pointer max-h-64"
                                    style={{
                                      display: index === 0 ? "block" : "none",
                                    }}
                                  />
                                )
                              )}
                            </SRLWrapper>
                          </SimpleReactLightbox>
                          <img
                            src={zoomIcon}
                            alt="zoomIcon"
                            className="absolute left-1 bottom-1"
                          />
                        </div>
                        <div className="w-8/12 p-3 md:w-full">
                          <h5 className="font-first text-default-blue text-xl font-semibold mb-1">
                            {language === "en"
                              ? product.frontmatter.title_en
                              : product.frontmatter.title}
                          </h5>
                          <h6 className="font-first text-default-dark text-[16px] font-normal mb-1 leading-5">
                            {product.frontmatter.customer}
                          </h6>
                          <p className="font-first text-default-opacity opacity-95 font-light text-[14px] mb-0">
                            {language === "en"
                              ? product.frontmatter.content_en
                              : product.frontmatter.content}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              )
            })}
          </TabPanels>
        </Tabs>
      </div>
    </Layout>
  )
}

export default Products

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    categories: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "urun_kategori" } } }
    ) {
      nodes {
        frontmatter {
          title
          title_en
          order
        }
      }
    }
    products: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product" } } }
    ) {
      nodes {
        frontmatter {
          title
          title_en
          customer
          kategori
          urun_resimleri {
            resim
          }
          content
          content_en
        }
      }
    }
  }
`
